import Link from "next/link";
import Image from "next/image";

import Button from "../components/button";
import Hero from "../components/hero";

import styles from "./login.module.scss";
import SeoMeta from "../components/seo-meta";
import { getSEOPageProps } from "../lib/seo";
import config from "../config";

const APP_STORE_LINK = "https://apps.apple.com/ca/app/east-room/id1595060251";
const GOOGLE_PLAY_STORE_LINK =
  "https://play.google.com/store/apps/details?id=com.officernd.eastroom&hl=en_CA&gl=US";

export default function LoginPage({ seo }) {
  return (
    <>
      <div className="container">
        <SeoMeta {...seo} />
        <Hero title="Login" />

        <div className={styles.LoginColumns}>
          <div className={styles.LeftCol}>
            <h3>Download the East Room App</h3>

            <p>
              Our member&apos;s app works as a mobile key to enter your East
              Room location. It also lets you connect with our community and
              staff, book boardrooms at a preferred rate, and more.
            </p>

            <div className={styles.AppPreviews}>
              <div className={styles.AppPreview}>
                <div className={styles.AppImage}>
                  <Image
                    src="/images/ios-app.png"
                    layout="fill"
                    alt="Download East Room on the App Store"
                  />
                </div>
                <Link href={APP_STORE_LINK} passHref>
                  <a
                    className={styles.StoreButton}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Download East Room on the App Store"
                  >
                    <Image
                      src="/images/app-store.png"
                      layout="fill"
                      alt="Download East Room on the App Store"
                    />
                  </a>
                </Link>
              </div>
              <div className={styles.AppPreview}>
                <div className={styles.AppImage}>
                  <Image
                    src="/images/android-app.png"
                    layout="fill"
                    alt="Download East Room on the Google Play Store"
                  />
                </div>

                <Link href={GOOGLE_PLAY_STORE_LINK} passHref>
                  <a
                    className={styles.StoreButton}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Download East Room on the Google Play Store"
                  >
                    <Image
                      src="/images/google-play.png"
                      layout="fill"
                      alt="Download East Room on the Google Play store"
                    />
                  </a>
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.RightCol}>
            <h3>Member Login</h3>

            <Link href="https://members.eastroom.com" passHref>
              <a target="_blank" rel="noreferrer">
                <Button outline className="text-left">
                  Login
                </Button>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export async function getStaticProps() {
  return {
    props: {
      seo: await getSEOPageProps({ resolvedUrl: "/login" })
    },
    revalidate: config.ISR_TIMING
  };
}
